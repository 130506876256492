.logo {
  height: 2.37vw;
  gap: .833vw;
}

.logo a {
  cursor: pointer;
  color: var(--color-white);
  align-items: center;
  gap: .833vw;
  text-decoration: none;
  display: flex;
}

body {
  width: auto;
  text-align: left;
  font-size: 25px;
  font-size: var(--font-size-xl);
  color: #ffffffd9;
  font-family: var(--font-montserrat);
  background-color: #000;
  margin: 0 auto;
  position: relative;
}

.header-container {
  height: 42vw;
  background-image: url("landing_alt_bg_new.073574e2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 2.08vw;
  padding-bottom: 5.2vw;
}

header {
  justify-content: space-between;
  gap: 2.6vw;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.header-two {
  background-image: none;
  background-position: 99% 15%;
  background-repeat: no-repeat;
  background-size: 62%;
}

.boost-earnings-container {
  width: 100%;
  background-color: #0000;
  background-image: none;
  background-position: 84% 42%;
  background-repeat: no-repeat;
  background-size: 104%;
  flex-direction: row;
  padding: 14.4vw 10% 3.43vw 10.96vw;
  display: flex;
  position: relative;
}

.boost-earnings-container-inner {
  width: 55%;
  padding-left: 5.7vw;
}

.boost-earnings-container .planet-ring {
  height: 100%;
  width: 500px;
  background-image: url("green-tiled-bg.7476ab0f.webp"), url("planet-with-ring-2-2x.91577d0c.webp");
  background-position: center, 114%;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, 75%;
}

.boost-earnings-container a {
  color: var(--color-gray-300);
  text-decoration: none;
}

.planet {
  font-size: clamp(3px, 1.3vw, 130px);
}

.logo-planet {
  height: 2.37vw;
}

nav ul {
  height: 2.37vw;
  align-items: center;
  margin-block-start: 0;
  list-style-type: none;
  display: flex;
}

nav ul li {
  padding-left: 2.6vw;
  font-size: clamp(3px, 1.04vw, 80px);
}

nav ul li a {
  text-align: center;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.launch-app-button-nav {
  text-align: center;
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  opacity: 1;
  cursor: pointer;
  border-radius: 5.2vw;
  padding: .7vw 1.4vw;
  font-size: clamp(3px, .8vw, 46px);
  box-shadow: 0 0 10px #00000029;
}

.launch-app-button-nav:hover {
  background-color: #fff;
  color: #000 !important;
}

.launch-app {
  line-height: .8vw;
}

.ring-planet {
  width: 600px;
}

.header-two {
  min-height: 400px;
  height: 40vw;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.green-button-item {
  box-sizing: border-box;
  text-align: center;
  color: var(--color-gray-300);
  color: #ffffffe6;
  width: -moz-fit-content;
  width: fit-content;
  background: linear-gradient(270deg, #5ef480, #207c2e);
  border-radius: 5.2vw;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-top: 3.8vw;
  padding: .8vw 2.13vw;
  font-size: clamp(3px, 1.2vw, 64px);
  display: flex;
  box-shadow: 0 0 30px #0000005e;
}

.green-button-item a {
  color: #ffffffd9;
  text-decoration: none;
}

.boost-your-earnings {
  text-transform: capitalize;
  color: #ffffffe6;
  padding-bottom: .6vw;
  font-size: clamp(3px, 3.38vw, 165px);
  display: block;
}

.bnb-chains {
  color: #55e5ff;
}

.with-bnb-chains-container {
  -webkit-text-stroke: 1px transparent;
  color: #ffffffe6;
  font-size: clamp(3px, 1.2vw, 124px);
}

.rectangle-div {
  height: .63vw;
  width: .63vw;
  border-radius: var(--br-12xs);
  background-color: var(--color-mediumspringgreen);
  position: absolute;
  inset: 84.51% 94.12% .43% 64.58%;
  transform: rotate(45deg);
}

.rectangle-div1 {
  height: .33vw;
  width: .33vw;
  border-radius: var(--br-12xs);
  inset: 43.51% 94.12% .43% 55.2%;
}

.on-planet {
  text-transform: capitalize;
  margin-bottom: .7vw;
  font-size: clamp(3px, 2.7vw, 152px);
  line-height: clamp(3px, 3.28vw, 163px);
}

.section-two {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 11.19vw;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
}

.find-the-best {
  font-size: clamp(3px, 1.6vw, 132px);
  line-height: clamp(3px, 2.03vw, 136px);
  display: inline-block;
}

.section-two-inner {
  width: 100%;
  justify-content: center;
  margin-top: 5.57vw;
  display: flex;
}

.section-two-inner-left {
  width: 100%;
  background-color: gray;
  background: var(--unnamed-color-17161b) 0% 0% no-repeat padding-box;
  opacity: 1;
  background: #17161b no-repeat padding-box padding-box;
  border-radius: 1.5vw;
  flex-direction: column;
  padding-top: 4.2vw;
  padding-bottom: 3.17vw;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-two-inner-right {
  width: 100%;
  background-color: gray;
  background: var(--unnamed-color-17161b) 0% 0% no-repeat padding-box;
  opacity: 1;
  background: #17161b no-repeat padding-box padding-box;
  border-radius: 1.5vw;
  flex-direction: column;
  margin-left: 1.5vw;
  padding-top: 4.2vw;
  padding-bottom: 4.2vw;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.aggregated-yield-text {
  flex-direction: column;
  padding-left: 3.17vw;
  padding-right: 1.61vw;
  font-size: clamp(3px, 2.5vw, 148px);
  font-weight: 600;
  display: flex;
}

.aggregated-yield-text p {
  margin: 0;
  line-height: clamp(3px, 3.2vw, 160px);
}

.aggregated-yield-desc {
  height: auto;
}

.aggregated-yield-desc p {
  -webkit-text-stroke: 1px transparent;
  float: left;
  text-align: left;
  letter-spacing: 0;
  color: #ffffffe6;
  width: 27.39vw;
  height: 7.03vw;
  margin-top: 2.03vw;
  margin-bottom: 3.17vw;
  padding-left: 2.9vw;
  padding-right: 2.98vw;
  font-size: clamp(3px, 1.04vw, 120px);
  line-height: 1.8vw;
}

.yield-pools {
  text-align: center;
  color: var(--color-skyblue-100);
  justify-content: center;
  padding-left: 3.125vw;
  padding-right: 3.125vw;
  font-size: .57vw;
  line-height: .729vw;
  display: flex;
}

.yield-pools-child {
  background-color: var(--color-darkslategray);
  opacity: 1;
  width: 8.17vw;
  border-radius: 1.56vw;
  flex-direction: column;
  align-items: center;
  margin-right: 1.25vw;
  padding-top: 1.5vw;
  padding-bottom: 1.3vw;
  display: flex;
  box-shadow: 0 10px 20px #212121;
}

.yield-pools .yield-pools-child:last-child {
  margin-right: 0;
}

.yield-pools-images {
  min-width: 3.69vw;
  height: 2.54vw;
  justify-content: center;
  margin-bottom: .729vw;
  display: flex;
  position: relative;
}

.yield-pools-images img:first-child {
  height: 2.5vw;
  position: absolute;
  left: 0;
}

.yield-pools-images img:nth-child(2) {
  height: 2.5vw;
  position: absolute;
  left: 1.3vw;
}

.yield-pools-images-right img {
  position: relative !important;
  left: 0 !important;
}

.apy-percent {
  color: #ffffffd9;
  font-size: .93vw;
  line-height: 1.14vw;
}

.pool-name {
  margin-bottom: .26vw;
  font-weight: 600;
}

.blue-button-container {
  width: 100%;
  cursor: pointer;
  justify-content: center;
  margin-top: 3.17vw;
  display: flex;
}

.blue-button-container a {
  color: #ffffffe6;
  text-decoration: none;
}

.blue-button-ui {
  border-radius: var(--br-81xl);
  opacity: 1;
  color: #ffffffd9;
  box-sizing: border-box;
  background: linear-gradient(270deg, #305eda, #55e5ff);
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  padding: .8vw 2.1vw;
  font-size: clamp(3px, 1.2vw, 130px);
  display: flex;
  box-shadow: 0 0 30px #0000005e;
}

.section-three {
  height: 69vw;
  background-image: url("green-coin-bg.2b3e4a25.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  margin-top: 13.95vw;
  display: flex;
}

.section-three-inner {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.section-three-inner:first-child {
  width: 33%;
  flex-basis: 50%;
  margin-left: 17%;
}

.section-three-inner:nth-child(2) {
  background-image: url("gamma-token-details.0a5b2bfb.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 135%;
  flex-basis: 50%;
}

.section-three-inner-coin-bg {
  width: 100%;
  background-image: url("gamma-token-details.0a5b2bfb.webp");
  background-position: 150%;
  background-repeat: no-repeat;
  background-size: 76%;
}

.supercharge-your-earnings-box {
  width: -moz-fit-content;
  width: fit-content;
  font-size: clamp(3px, 2.6vw, 152px);
  display: inline-block;
}

.gamma-is-a-box {
  width: -moz-fit-content;
  width: fit-content;
  -webkit-text-stroke: 1px transparent;
  color: #ffffffd9;
  margin-top: 1.04vw;
  font-size: clamp(3px, 1.27vw, 124px);
  font-weight: 500;
  line-height: 2.3vw;
}

.gamma1-text {
  color: var(--color-mediumspringgreen);
}

.section-four {
  padding-left: 16.61%;
  padding-right: 16.61%;
}

.section-four-mobile {
  display: none;
}

.section-four-inner-items {
  opacity: 1;
  background: none;
  border-radius: 1.56vw;
  flex-direction: column;
  padding: 0;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-four-inner-items:first-child {
  flex-basis: 68%;
}

.section-four-inner-items:nth-child(2) {
  flex-basis: 32%;
}

.section-four-inner-left-top-items {
  text-align: center;
  font-size: 20px / 32px;
  background: var(--unnamed-color-17161b) 0% 0% no-repeat padding-box;
  background: #17161b no-repeat padding-box padding-box;
  border-radius: 1.5vw;
  flex-direction: column;
  flex-basis: 50%;
  align-items: center;
  padding: 2.13vw 3.8vw 1vw 3.4vw;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-four-inner-left-top-items:nth-child(2) {
  background-color: #222129 !important;
}

.section-four-inner-container {
  gap: 1.87vw;
  display: flex;
}

.section-four-inner-left {
  flex-direction: column;
  gap: 1.8vw;
  display: flex;
}

.section-four-inner-left-top {
  gap: 1.8vw;
  display: flex;
}

.section-four-inner-left-bottom {
  background: var(--unnamed-color-17161b) 0% 0% no-repeat padding-box;
  background: #17161b no-repeat padding-box padding-box;
  border-radius: 1.5vw;
  gap: 3.38vw;
  padding: 1.25vw 4.12vw 1.25vw 2.55vw;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-four-inner-left-bottom-items img {
  width: 6.8vw;
  height: 7vw;
}

.section-four-inner-left-bottom-items p {
  color: #ffffffe6;
  font-size: clamp(8px, 1.04vw, 100px);
  font-weight: 500;
  line-height: 1.66vw;
}

.section-four-inner-left-bottom-items:first-child {
  flex-basis: 20%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-four-inner-left-bottom-items:nth-child(2) {
  flex-basis: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-four-inner-left-top-items:first-child img {
  width: 9.7vw;
  height: 12.1vw;
}

.section-four-inner-left-top-items:nth-child(2) img {
  width: 6.7vw;
  height: 6.8vw;
}

.section-four-inner-left-top-items p {
  text-align: left;
  color: #ffffffe6;
  margin-top: 1.97vw;
  font-size: clamp(8px, 1.04vw, 100px);
  line-height: 1.6vw;
}

.section-four-inner-left-top-items:first-child p {
  color: #ffffffe6;
  margin-top: 1.97vw;
}

.section-four-inner-right {
  height: 100%;
  flex-direction: column;
  gap: 1.56vw;
  display: flex;
}

.section-four-inner-right-top {
  background: var(--unnamed-color-383743) 0% 0% no-repeat padding-box;
  background: #383743 no-repeat padding-box padding-box;
  border-radius: 1.5vw;
  flex-direction: column;
  flex-basis: 37%;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-four-inner-right-top p {
  color: #ffffffe6;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-left: 2.3vw;
  padding-right: 2.03vw;
  font-size: clamp(4px, 1.6vw, 100px);
  font-weight: bold;
  line-height: clamp(4px, 2.1vw, 120px);
}

.section-four-inner-right-top p:nth-child(2) {
  margin-top: 0;
  margin-bottom: 1.92vw;
  padding-right: 5.2vw;
}

.section-four-inner-right-bottom {
  background: var(--unnamed-color-222129) 0% 0% no-repeat padding-box;
  background: #222129 no-repeat padding-box padding-box;
  border-radius: 1.5vw;
  flex-direction: column;
  flex-basis: 63%;
  justify-content: flex-start;
  align-items: center;
  gap: 1.25vw;
  padding: 2.18vw 3.28vw 1.5vw;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-four-inner-right-bottom p {
  text-align: left;
  color: #ffffffe6;
  margin: 0;
  font-size: clamp(8px, 1.04vw, 100px);
  line-height: 1.66vw;
}

.section-four-inner-right-bottom img {
  width: 9.6vw;
  height: 10.3vw;
}

.section-five {
  height: 67vw;
  background-image: url("aqua-coin-details-bg1.b27bfe87.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 17.65vw;
  display: flex;
}

.section-five-inner-bg {
  width: 100%;
  height: 67vw;
  background-image: url("aqua-coin-details1.99e2f5bd.webp");
  background-position: -39%;
  background-repeat: no-repeat;
  background-size: 77%;
  justify-content: end;
  align-items: center;
  display: flex;
}

.section-five-inner:first-child {
  width: 35%;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  padding-left: 54%;
  padding-right: 16%;
  display: flex;
}

.section-five-inner:nth-child(2) {
  padding-left: 5%;
}

.be-a-part-container {
  text-transform: inherit;
  color: #ffffffe6;
  font-size: clamp(3px, 2.5vw, 120px);
  display: block;
}

.aqua1 {
  color: #55e5ff;
}

.aqua-becomes-scarcer {
  -webkit-text-stroke: 1px transparent;
  color: #ffffffd9;
  margin-top: 1.6vw;
  font-size: clamp(3px, 1.2vw, 100px);
  font-weight: 500;
  line-height: 2.23vw;
}

.section-six {
  gap: 1.7vw;
  margin-top: -8.07vw;
  padding-left: 17.23vw;
  padding-right: 17.23vw;
  display: flex;
}

.section-six-mobile {
  display: none;
}

.section-six-inner-left-top-items {
  text-align: center;
  background: var(--unnamed-color-17161b) 0% 0% no-repeat padding-box;
  min-height: 15vw;
  background: #17161b no-repeat padding-box padding-box;
  border-radius: 1.5vw;
  flex-direction: column;
  flex-basis: 50%;
  align-items: center;
  padding: 3.38vw 2.7vw 2.5vw;
  font-size: clamp(3px, 1.04vw, 100px);
  line-height: clamp(3px, 1.66vw, 120px);
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-six-inner-left-top-items:nth-child(2) {
  background-color: #222129 !important;
  padding-top: 1.97vw !important;
}

.section-six-inner-left-top-items:nth-child(3) {
  background-color: #383743 !important;
}

.section-six-inner-left-top-items:first-child img {
  width: 9.6vw;
  height: 10vw;
}

.section-six-inner-left-top-items:nth-child(2) img {
  width: 7.5vw;
  height: 10.3vw;
}

.section-six-inner-left-top-items:nth-child(3) img {
  width: 7.8vw;
  height: 8.5vw;
}

.section-six-inner-left-top-items div {
  height: 9.32vw;
  align-items: center;
  display: flex;
}

.section-six-inner-left-top-items p {
  text-align: left;
  flex-basis: 30%;
  margin: 0;
  font-size: clamp(3px, 1.04vw, 140px);
  line-height: clamp(3px, 1.7vw, 150px);
}

.section-six-inner-left-top-items:first-child p {
  margin-top: 1.31vw !important;
}

.section-six-inner-left-top-items:nth-child(2) p {
  margin-top: 2.6vw !important;
}

.section-six-inner-left-top-items:nth-child(3) p {
  margin-top: 1.3vw !important;
}

.section-seven {
  justify-content: center;
  padding-top: 13.22vw;
  padding-left: 15.83vw;
  padding-right: 15.83vw;
  display: block;
}

.section-seven-inner {
  width: 100%;
}

.section-seven-inner-top {
  padding-bottom: 8.59vw;
}

.section-seven-inner-top p {
  text-align: center;
  letter-spacing: 0;
  color: #fffffff2;
  margin: 0;
  font-size: clamp(6px, 2.7vw, 100px);
  font-weight: 700;
  line-height: clamp(6px, 3.38vw, 120px);
}

.section-seven-inner-bottom-container {
  width: 100%;
  justify-content: space-between;
  gap: 1.92vw;
  display: flex;
}

.section-seven-inner-bottom {
  width: 100%;
  gap: 1.92vw;
  display: flex;
}

.section-seven-inner-bottom-items {
  width: 100%;
  border-radius: 2.76vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.02vw 1.5vw 2.8vw;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.section-seven-inner-bottom:first-child .section-seven-inner-bottom-items:first-child {
  background-color: var(--color-darkslategray);
}

.section-seven-inner-bottom:first-child .section-seven-inner-bottom-items:nth-child(2), .section-seven-inner-bottom:nth-child(2) .section-seven-inner-bottom-items:first-child {
  background-color: var(--color-gray-100);
}

.section-seven-inner-bottom:nth-child(2) .section-seven-inner-bottom-items:nth-child(2) {
  background-color: var(--color-gray-200);
}

.section-seven-inner-bottom-items img {
  width: 5.2vw;
}

.section-seven-inner-bottom-items p {
  text-align: center;
  margin-top: .52vw;
  margin-bottom: 0;
  font-size: clamp(6px, 1.04vw, 60px);
}

.section-seven-inner-bottom-items b {
  text-align: center;
  margin-top: 1.19vw;
  font-size: clamp(6px, 1.4vw, 100px);
}

.section-eight {
  flex-direction: column;
  padding-bottom: 5.31vw;
  padding-left: 15%;
  padding-right: 15%;
  display: block;
}

.section-eight-top {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 13.17vw;
  display: flex;
}

.section-eight-bottom {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5.52vw;
  display: flex;
}

.section-eight-img-container {
  justify-content: center;
  display: flex;
  position: relative;
}

.section-eight-img-container img {
  height: 44.89vw;
}

.section-eight-top b {
  text-transform: capitalize;
  font-size: clamp(6px, 2.7vw, 150px);
  line-height: clamp(30px, 3.28vw, 160px);
}

.section-eight-top p {
  margin-bottom: 2.65vw;
  font-size: clamp(4px, 1.25vw, 50px);
}

.section-eight-bottom b {
  text-transform: capitalize;
  color: #ffffffe6;
  font-size: clamp(8px, 2.7vw, 152px);
  line-height: clamp(5px, 3.28vw, 163px);
}

.section-eight-img-container .social-cards {
  border-radius: 1.56vw;
  box-shadow: 20px 20px 40px #00000045;
}

.social-card-anchor {
  color: #fff;
  text-decoration: none;
}

.section-eight-img-container .social-card-medium {
  background: #17161b no-repeat padding-box padding-box;
  position: absolute;
  top: 36%;
  left: 12%;
}

.section-eight-img-container .social-card-twitter {
  background: #222129 no-repeat padding-box padding-box;
  position: absolute;
  top: 59%;
  left: 59%;
}

.section-eight-img-container .social-card-telegram {
  background: #383743 no-repeat padding-box padding-box;
  position: absolute;
  top: 14%;
  left: 55%;
}

.section-eight-img-container .social-cards .social-cards-inner {
  padding: 1.8vw 2.8vw;
  display: flex;
}

.social-cards-img {
  align-items: center;
  display: flex;
}

.social-cards-img img {
  width: 3.1vw;
  height: 2.4vw;
}

.social-cards-head {
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin-left: 1.66vw;
  display: flex;
}

.social-cards-head b {
  font-size: clamp(5px, 1.45vw, 70px);
}

.social-cards-head p {
  margin: .2vw 0 0;
  font-size: clamp(3px, .8vw, 40px);
  font-weight: 500;
}

.section-nine {
  background: linear-gradient(0deg, #0d326d 0%, #000 100%) no-repeat padding-box padding-box;
  flex-direction: column;
  display: flex;
}

.section-nine-top {
  flex-direction: column;
  padding-bottom: 10vw;
  padding-left: 18.02vw;
  padding-right: 18.02vw;
  display: flex;
}

.section-nine-top-img-items {
  display: none;
}

.section-nine-top-items {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.43vw;
  display: flex;
}

.section-nine-top img {
  mix-blend-mode: luminosity;
  height: 1.8vw;
  cursor: pointer;
}

.coinmarketcap {
  height: 2vw !important;
}

.coingecko {
  height: 3.22vw !important;
}

.halborn {
  height: 1.37vw !important;
}

.thena {
  height: 1.52vw !important;
}

.helio {
  height: 1.88vw !important;
}

.frax {
  height: 3.03vw !important;
}

.mexc {
  height: 1.35vw !important;
}

.defillama {
  height: 3.21vw !important;
}

.staderlabs {
  height: 2.2vw !important;
}

.dappradar {
  height: 1.62vw !important;
}

.img-height-big {
  height: 1.9vw !important;
}

.img-height-large {
  height: 3.1vw !important;
}

.trustedby-link {
  align-items: end;
  display: flex;
}

.footer {
  border: 0 solid #ffffff26;
  border-top-width: .1vw;
  flex-direction: column;
  display: flex;
}

.footer-top-container {
  justify-content: space-between;
  padding: 5vw 9.94vw;
  display: flex;
}

.footer-top-container .footer-item:first-child {
  flex-basis: 40%;
}

.mobile-footer-item {
  display: none;
}

.footer-item {
  flex-direction: column;
  display: flex;
}

.footer-item b {
  color: var(--color-skyblue-100);
  text-transform: uppercase;
  font-size: clamp(3px, 1.4vw, 120px);
  font-weight: 800;
}

.footer-item > p:first-of-type {
  margin-top: 2.1vw;
}

.footer-item p a, .footer-item p {
  color: #ffffffbf;
  margin-top: .9vw;
  margin-bottom: 0;
  font-size: clamp(3px, 1.04vw, 120px);
  font-weight: 300;
}

.footer-item p a {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.footer-top-container .social-links {
  gap: 1.97vw;
  display: flex;
}

.footer-social {
  background-color: var(--color-darkslateblue);
  width: 3.6vw;
  height: 3.2vw;
  border-radius: 1vw;
  justify-content: center;
  align-items: center;
  margin-top: 1.8vw;
  display: flex;
  box-shadow: 20px 20px 40px #00000045;
}

.social-links a {
  cursor: pointer;
}

.footer-social img {
  height: 1.2vw;
}

.footer-bottom-container {
  box-sizing: border-box;
  border-top: .104vw solid #ffffff26;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  padding: 2.96vw 5% 3.75vw;
  display: flex;
}

.footer-bottom-container .logo {
  display: flex;
}

.footer-bottom-container .logo-planet {
  height: 3.125vw;
}

.footer-bottom-container .logo b {
  height: 3.28vw;
  align-items: center;
  font-size: clamp(3px, 1.6vw, 131px);
  display: flex;
}

.gamma-arrow-icon {
  width: .78vw;
  height: .52vw;
  padding-left: .52vw;
}

.footer-bottom-right {
  justify-items: center;
  gap: 3vw;
  display: flex;
}

.coins-prices-footer {
  align-items: center;
  gap: .5vw;
  display: flex;
}

.coins-prices-footer img {
  height: 1.5vw;
}

.coins-prices-footer b {
  font-size: .8vw;
}

.footer-bottom-left {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.footer-bottom-right .blue-button-container {
  margin-top: 0;
}

.mobile-menu-icon {
  display: none;
}

.mobile-menu {
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
  min-width: 100vw;
  background: #000;
  transition: all .3s;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.mobileNavContainer {
  box-sizing: border-box;
  height: 100%;
  height: 100%;
  background-color: #000;
  flex-direction: column;
  padding: 50px 0 0;
  transition: all .3s;
  display: flex;
  overflow-y: auto;
}

.planet-icon {
  height: 6.61vw;
  margin-right: 7px;
}

.mob-nav-header p {
  color: #fff;
  margin: 0;
  font-size: 4.58vw;
  font-weight: bold;
}

.cross-icon {
  width: 20px;
  height: 20px;
}

.mobile-planet-icon {
  align-items: center;
  display: flex;
}

.mob-nav-header-inner {
  justify-content: space-between;
  display: flex;
}

.mobile-menu-inner {
  height: 100%;
  box-sizing: border-box;
  background-color: #000;
  padding: 24.5px 20px 50px;
  transition: all .3s;
  overflow-y: auto;
}

.mob-nav-textCont p {
  color: #ffffffe6;
  margin: 0;
  font-weight: bold;
}

.mob-nav-textCont a {
  cursor: pointer;
  text-align: center;
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.mob-nav-itemCont {
  margin-top: 20px;
  padding-left: 20px;
}

.mob-nav-itemCont .last-item {
  margin-bottom: 0;
}

.mob-nav-itemCont a {
  text-decoration: none;
}

.mob-nav-icon {
  height: 44px;
  width: 44px;
  margin-right: 20px;
}

div .mob-nav-cont {
  float: left;
  width: 100%;
  align-items: center;
  padding-left: 0;
  display: flex;
}

.mob-nav-cont > div.mob-sidebar-links {
  justify-content: space-between;
  display: flex;
}

.mob-nav-cont > div.mob-sidebar-links > a {
  display: contents;
}

.menu-divider {
  width: 100%;
  height: 1px;
  color: #ffffff26;
  background-color: #ffffff26;
  margin: 30px 0;
}

.menu-title {
  color: #fff;
  opacity: 1;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
}

.menu-description {
  color: #fff;
  opacity: .4;
  margin: 2px 0 0;
  font-size: 14px;
  line-height: 18px;
}

.mob-nav-cont b {
  color: #fff;
  opacity: 1;
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}

.display-block {
  display: block !important;
}

.footer-blue-button-ui {
  background: linear-gradient(270deg, #3037da 0%, #55b7ff 100%) no-repeat padding-box padding-box;
  flex-direction: row;
  align-items: center;
  padding: .41vw .9vw .36vw;
  font-size: clamp(3px, .8vw, 30px);
  display: flex;
}

.future-together {
  font-size: clamp(4px, 1.45vw, 128px) !important;
  font-weight: 100 !important;
}

.footer-protocol-help {
  display: none;
}

.coins-prices-footer-container {
  gap: 3vw;
  display: flex;
}

@media screen and (device-width <= 767px), only screen and (-webkit-device-pixel-ratio: 3) and (width <= 767px), only screen and (-moz-device-pixel-ratio >= 3) and (width <= 767px), only screen and (-o-min-device-pixel-ratio: 3) and (width <= 767px), only screen and (min-device-pixel-ratio: 3) and (width <= 767px), only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait), only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait), only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait), only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait), only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait), only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait), only screen and (width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait), only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .section-two-inner {
    flex-direction: column;
    gap: 7.63vw;
    margin-top: 12.72vw;
  }

  nav {
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #0ff;
    display: none;
    position: absolute;
  }

  nav ul {
    flex-direction: column;
  }

  .nav-header {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    display: flex;
    position: absolute;
    top: 0;
  }

  .fa-bars {
    display: none;
  }

  .section-seven-inner-bottom {
    flex-direction: column;
    gap: 16px;
  }

  .footer-top-container, .footer-bottom-container {
    flex-direction: column;
  }

  .section-four-inner-left-top {
    flex-direction: column;
    gap: 16px;
  }

  .section-two-inner-right {
    margin-left: 0;
  }

  .header-container {
    height: auto;
  }

  .section-two-inner-left, .section-two-inner-right {
    width: auto;
    padding: 30px 20px;
  }

  .header-container {
    padding-bottom: 0;
  }

  .section-two {
    margin-top: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .aggregated-yield-desc {
    height: auto;
  }

  .section-three {
    margin-top: 50px;
  }

  .section-three-inner:first-child {
    width: auto;
    margin-left: 10%;
    margin-right: 10%;
  }

  .gamma-is-a-box {
    line-height: 5.3vw;
  }

  .section-three-inner-coin-bg {
    background-position: 1050%;
    background-size: 98%;
  }

  .section-four {
    margin-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
  }

  .section-four-inner-left-top-items:first-child img {
    width: 32vw;
    height: 40vw;
  }

  .section-four-inner-left-top-items:nth-child(2) img {
    width: 39vw;
    height: 40vw;
  }

  .section-four-inner-left-bottom-items img {
    width: 19vw;
    height: 20vw;
  }

  .section-four-inner-left-bottom, .section-four-inner-right, .section-four-inner-left {
    gap: 16px;
  }

  .section-four-inner-right-bottom img {
    width: 37vw;
    height: 40vw;
  }

  .section-four-inner-right-top p:nth-child(2) {
    padding-right: 0;
  }

  .section-five {
    height: auto;
    margin-top: 50px;
  }

  .section-five-inner-bg {
    height: auto;
    background-position: 305%;
    background-size: 90%;
    justify-content: center;
  }

  .section-six-inner-left-top-items {
    padding-left: 16px;
    padding-right: 16px;
  }

  .section-six-inner-left-top-items:first-child img {
    width: 38.7vw;
    height: 40vw;
  }

  .section-six-inner-left-top-items:nth-child(2) img {
    width: 29vw;
    height: 40vw;
  }

  .section-six-inner-left-top-items:nth-child(3) img {
    width: 36vw;
    height: 40vw;
  }

  .section-six-inner-left-top-items div {
    height: auto;
  }

  .section-seven-inner-bottom-items {
    width: auto;
  }

  .section-eight-img-container .social-cards {
    height: auto;
    width: 40vw;
    border-radius: 15px;
  }

  .section-eight-img-container .social-card-medium {
    left: -10%;
  }

  .section-eight {
    padding-bottom: 50px;
  }

  .future-together {
    line-height: inherit !important;
  }

  .footer-bottom-container {
    padding: 30px 0 35px;
  }

  .coins-prices-footer {
    gap: 2.54vw;
  }

  .footer-blue-button-ui {
    height: 38px;
  }

  .blue-button-ui {
    font-size: clamp(4px, 1vw, 120px);
  }

  .section-nine-top-items {
    gap: .26vw;
  }

  .footer-social {
    width: 11.25vw;
    height: 10vw;
    border-radius: .52vw;
  }

  .footer-bottom-right .blue-button-container {
    justify-content: right !important;
  }

  .section-seven-inner-bottom-items p {
    font-size: clamp(4px, 1.4vw, 100px);
  }

  header {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    z-index: 10;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background: #0006;
    border-bottom: .354vw solid #ffffff26;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 7.63vw 5.08vw 5.08vw;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .header-container {
    padding-top: 0;
  }

  .logo-planet {
    height: 6.61vw;
  }

  .logo a {
    height: 100%;
    color: #ffffffe6;
    gap: 1.88vw;
  }

  .planet {
    font-size: 4.58vw;
  }

  .logo {
    height: auto;
  }

  .mobile-menu-icon {
    display: flex;
  }

  .boost-earnings-container {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    background-image: url("planet_with_rings.931ff767.webp");
    background-position: 50% 34.6vw;
    background-size: 120.61vw;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .boost-earnings-container-inner {
    width: 100%;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding-top: 115.52vw;
    padding-bottom: 17.3vw;
    padding-left: 0;
    display: flex;
  }

  .boost-your-earnings {
    font-size: clamp(3px, 7.63vw, 165px);
  }

  .with-bnb-chains-container {
    font-size: 4.07vw !important;
    line-height: 8.16vw !important;
  }

  .green-button-item {
    margin-top: 10.17vw;
    padding: 2.03vw 4.58vw;
  }

  .green-button-item b {
    font-size: 4.07vw;
    line-height: 4.32vw;
  }

  .header-two {
    height: auto;
    background-position: 91% 38%;
    background-size: 190%;
  }

  .on-planet {
    margin-bottom: 2.54vw;
    font-size: clamp(3px, 7.63vw, 152px);
    line-height: clamp(3px, 9.41vw, 163px);
  }

  .find-the-best {
    font-size: clamp(3px, 4.07vw, 132px);
    line-height: clamp(3px, 6.1vw, 136px);
  }

  .section-two-inner {
    margin-top: 9.88vw;
  }

  .section-two-inner-left, .section-two-inner-right {
    border-radius: 5.08vw;
    padding: 12.72vw 7.63vw;
  }

  .aggregated-yield-text {
    padding-left: 0;
    padding-right: 0;
    font-size: clamp(3px, 7.63vw, 148px);
  }

  .aggregated-yield-text p {
    line-height: 9.41vw;
  }

  .aggregated-yield-desc p {
    width: auto;
    margin-top: 5.08vw;
    margin-bottom: 10.16vw;
    padding-left: 0;
    padding-right: 0;
    font-size: clamp(3px, 4.07vw, 120px);
    line-height: 6.1vw;
  }

  .yield-pools {
    flex-direction: row;
  }

  .yield-pools-images {
    height: 6.36vw;
    min-width: 10.43vw;
    margin-bottom: 1.9vw;
  }

  .yield-pools-images img:first-child {
    height: 6.36vw;
  }

  .yield-pools-images img:nth-child(2) {
    height: 6.36vw;
    left: 3.56vw;
  }

  .aggregated-yield-desc p {
    height: auto;
  }

  .pool-name {
    margin-bottom: .98vw;
    font-size: 1.27vw;
    line-height: 1.52vw;
  }

  .yield-pools-child {
    min-width: 20.96vw;
    border-radius: 5.08vw;
    margin-right: 3.21vw;
    padding-top: 4vw;
    padding-bottom: 3.6vw;
  }

  .apy-percent {
    font-size: 2.29vw;
    line-height: 2.79vw;
  }

  .blue-button-ui {
    padding: 2.03vw 4.58vw;
    font-size: 4.07vw;
    line-height: 4.32vw;
  }

  .blue-button-container {
    margin-top: 10.17vw;
  }

  .section-three {
    height: 216.13vw;
    background-image: url("big_gamma_bg_mobile.3a271a72.webp");
    background-position: 75%;
    background-size: 166%;
    margin-top: 0;
  }

  .section-three-inner-coin-bg {
    background-image: url("big_GAMMA_coin_mobile@3x.381fc7ee.webp");
    background-position: 50% -34%;
    background-size: 190%;
  }

  .section-three-inner:first-child {
    height: auto;
    margin-left: 7.63vw;
    margin-right: 7.63vw;
    padding-top: 138.42vw;
  }

  .supercharge-your-earnings-box {
    font-size: 7.63vw;
    line-height: 9.41vw;
  }

  .gamma1-text {
    color: #ffffffd9;
  }

  .gamma-is-a-box {
    margin-top: 7.63vw;
    font-size: 4.07vw;
    line-height: 6.1vw;
  }

  .section-three-inner .green-button-item {
    margin-top: 7.63vw;
  }

  .section-four {
    display: none;
  }

  .section-four-mobile {
    margin-top: 7.63vw;
    display: flex;
  }

  .section-four-mobile .section-four-inner-left, .section-four-mobile .section-four-inner-right {
    gap: 7.63vw;
  }

  .section-four-mobile .section-four-inner-left-one {
    background: #17161b;
    border-radius: 5.08vw;
    flex-direction: column;
    align-items: center;
    padding: 7.63vw 5.08vw;
    display: flex;
  }

  .section-four-mobile .section-four-inner-left-one img {
    height: 125.61px;
    width: 25.44vw;
    margin-bottom: 5.08vw;
  }

  .section-four-mobile .section-four-inner-left-one p {
    margin: 0;
    font-size: 3.56vw;
    line-height: 6.1vw;
  }

  .section-four-mobile .section-four-inner-container {
    flex-direction: row;
    gap: 7.37vw;
    padding-left: 7.63vw;
    padding-right: 7.63vw;
  }

  .section-four-mobile .section-four-inner-left-two {
    background: #383743;
    border-radius: 5.08vw;
    flex-direction: column;
    padding: 7.63vw 5.08vw;
    display: flex;
  }

  .section-four-mobile .section-four-inner-left-two p {
    margin: 0;
    font-size: 4.07vw;
    font-weight: bold;
    line-height: 8.14vw;
  }

  .section-four-mobile .section-four-inner-items {
    flex-basis: 50%;
  }

  .section-four-mobile .section-four-inner-left-three {
    background: #222129;
    border-radius: 5.08vw;
    flex-direction: column;
    align-items: center;
    padding: 7.63vw 5.08vw;
    display: flex;
  }

  .section-four-mobile .section-four-inner-left-three img {
    height: 27.02vw;
    width: 25.44vw;
    margin-bottom: 7.63vw;
  }

  .section-four-mobile .section-four-inner-left-three p {
    margin: 0;
    font-size: 3.56vw;
    line-height: 6.1vw;
  }

  .section-four-mobile .section-four-inner-right-one {
    background: #222129;
    border-radius: 5.08vw;
    flex-direction: column;
    align-items: center;
    padding: 7.63vw 5.08vw;
    display: flex;
  }

  .section-four-mobile .section-four-inner-right-one img {
    width: 22.16vw;
    height: 22.59vw;
    margin-bottom: 7.63vw;
  }

  .section-four-mobile .section-four-inner-right-one p {
    margin: 0;
    font-size: 3.56vw;
    line-height: 6.1vw;
  }

  .section-four-mobile .section-four-inner-right-two {
    background: #17161b;
    border-radius: 5.08vw;
    flex-direction: column;
    align-items: center;
    padding: 7.63vw 5.08vw;
    display: flex;
  }

  .section-four-mobile .section-four-inner-right-two img {
    height: 26.83vw;
    width: 25.44vw;
    margin-bottom: 7.63vw;
  }

  .section-four-mobile .section-four-inner-right-two p {
    margin: 0;
    font-size: 3.56vw;
    line-height: 6.1vw;
  }

  .section-five {
    height: 244.91vw;
    background-image: url("aqua_coin_bg_mobile.38b47e09.webp");
    background-position: 61% -41%;
    background-size: 176%;
    margin-top: 7.63vw;
  }

  .section-five-inner-bg {
    background-image: url("aqua_coin_details_mobile.9d368116.webp");
    background-position: 50% -48%;
    background-size: 179%;
  }

  .section-five-inner:first-child {
    width: 100%;
    flex-basis: auto;
    padding-left: 7.63vw;
    padding-right: 7.63vw;
  }

  .be-a-part-container {
    color: #ffffffe6;
    margin-top: 83.04vw;
    font-size: 7.63vw;
    line-height: 9.41vw;
  }

  .aqua1 {
    color: #ffffffe6;
  }

  .aqua-becomes-scarcer {
    margin-top: 7.63vw;
    font-size: 4.07vw;
    line-height: 6.1vw;
  }

  .section-five-inner .blue-button-container {
    margin-top: 7.63vw;
  }

  .section-six {
    display: none;
  }

  .section-six-mobile {
    flex-direction: column;
    gap: 7.63vw;
    margin-top: -30vw;
    display: flex;
  }

  .section-six-mobile .section-six-inner-left-top-items {
    background: none;
    flex-direction: row;
    gap: 7.63vw;
    padding-left: 7.63vw;
    padding-right: 7.63vw;
    display: flex;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-one {
    background: #17161b;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-two {
    background: #222129;
  }

  .section-six-mobile .section-six-inner-left-bottom-items {
    background: #383743;
    border-radius: 5.08vw;
    flex-direction: row;
    gap: 7.63vw;
    margin-left: 7.63vw;
    margin-right: 7.63vw;
    padding: 7.63vw;
    display: flex;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-one, .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-two {
    border-radius: 5.08vw;
    flex-direction: column;
    padding: 10.17vw 6.1vw 7.63vw;
    display: flex;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-one {
    align-self: baseline;
    padding-bottom: 13.45vw;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-two {
    padding-top: 7.63vw;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-one p, .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-two p {
    margin: 2.03vw 0 0;
    font-size: 3.562vw;
    line-height: 6.1vw;
  }

  .section-six-mobile .section-six-inner-left-bottom-items p {
    margin: 0;
    font-size: 3.562vw;
    line-height: 6.1vw;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-one img {
    width: 26.4vw;
    height: 27.15vw;
  }

  .section-six-mobile .section-six-inner-left-top-items .section-six-inner-left-top-item-two img {
    width: 21.4vw;
    height: 29.3vw;
  }

  .section-six-mobile .section-six-inner-left-bottom-items img {
    width: 24.9vw;
    height: 27.15vw;
  }

  .section-seven {
    padding: 25.44vw 10.17vw 27.93vw;
  }

  .section-seven-inner-top p {
    font-size: 7.63vw;
    line-height: 9.414vw;
  }

  .section-seven-inner-top {
    padding-bottom: 7.63vw;
  }

  .section-seven-inner-bottom-container {
    flex-direction: column;
  }

  .section-seven-inner-bottom-items img {
    width: 15.26vw;
  }

  .section-seven-inner-bottom-items b {
    margin-top: 2.25vw;
    font-size: 4.07vw;
  }

  .section-seven-inner-bottom-items p {
    margin-top: .508vw;
    font-size: 3.56vw;
  }

  .section-seven-inner-bottom-items {
    border-radius: 5.08vw;
    flex: 1;
    padding: 5.61vw 6.61vw;
  }

  .section-seven-inner-bottom {
    flex-flow: wrap;
    gap: 2.29vw;
  }

  .section-eight {
    padding-bottom: 15.02vw;
    padding-left: 0;
    padding-right: 0;
  }

  .section-eight-top {
    margin-top: 0;
  }

  .section-eight-top b {
    color: #ffffffeb;
    font-size: 7.63vw;
    line-height: 9.414vw;
  }

  .section-eight-top p {
    margin-bottom: 0;
    font-size: 4.07vw;
    line-height: 6.1vw;
  }

  .section-eight-img-container .social-cards .social-cards-inner {
    padding: 2.54vw 4.07vw;
  }

  .social-cards-img img {
    width: 6.93vw;
    height: 5.81vw;
  }

  .social-cards-head {
    align-items: baseline;
    margin-left: 3.05vw;
  }

  .social-cards-head b {
    font-size: 3.56vw;
  }

  .social-cards-head p {
    margin-top: 1.24vw;
    font-size: 2.54vw;
  }

  .section-eight-middle {
    margin-top: 14.66vw;
  }

  .section-eight-img-container {
    overflow: hidden;
  }

  .section-eight-img-container .social-cards {
    width: auto;
  }

  .section-eight-img-container .social-card-telegram {
    top: 8%;
    left: 45%;
  }

  .section-eight-img-container .social-card-medium {
    top: 39%;
    left: 8%;
  }

  .section-eight-img-container .social-card-twitter {
    top: 70%;
    left: 45%;
  }

  .section-eight-bottom {
    margin-top: 23vw;
  }

  .section-eight-bottom b {
    font-size: 7.63vw;
  }

  .section-eight-planet-ring {
    width: 120.61vw !important;
    height: 84.47vw !important;
  }

  .section-nine-top-items {
    display: none;
  }

  .section-nine-top-img-items {
    justify-content: space-between;
    display: flex;
  }

  .helio {
    width: 22.97vw !important;
    height: 5.3vw !important;
  }

  .mexc {
    width: 38.87vw !important;
    height: 4.41vw !important;
  }

  .thena {
    width: 23.53vw !important;
    height: 3.76vw !important;
  }

  .frax {
    width: 22.98vw !important;
    height: 7.427vw !important;
  }

  .halborn {
    width: 38.01vw !important;
    height: 4.59vw !important;
  }

  .coingecko {
    width: 30vw !important;
    height: 8.4vw !important;
  }

  .defillama {
    width: 30vw !important;
    height: 10.25vw !important;
  }

  .dappradar {
    width: 29.92vw !important;
    height: 4.44vw !important;
  }

  .coinmarketcap {
    width: 39.45vw !important;
    height: 6.29vw !important;
  }

  .staderlabs {
    width: auto !important;
    height: 7.89vw !important;
  }

  .section-nine-top {
    padding-bottom: 30.41vw;
    padding-left: 15.26vw;
    padding-right: 15.26vw;
  }

  .section-nine-top-img-items a {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .section-nine-top-img-items a:nth-child(2) {
    margin-left: 7.12vw;
  }

  .section-nine-top-img-items:nth-child(2) {
    margin-top: 7.88vw;
  }

  .section-nine-top-img-items:nth-child(3) {
    margin-top: 6.98vw;
  }

  .section-nine-top-img-items:nth-child(4) {
    margin-top: 5.87vw;
  }

  .section-nine-top-img-items:nth-child(5) {
    margin-top: 5.3vw;
  }

  .footer {
    border-top-width: 0;
    padding-left: 5.08vw;
    padding-right: 5.08vw;
  }

  .footer-top-container {
    border: .508vw solid #ffffff26;
    border-width: .508vw 0;
    padding-top: 7.63vw;
    padding-left: 2.54vw;
    padding-right: 2.54vw;
  }

  .footer-item b {
    color: #55e5ff;
    font-size: 16px;
    font-weight: 600;
  }

  .footer-top-container > .footer-item:first-child > p {
    margin-top: 1.78vw !important;
    font-size: 3.56vw !important;
  }

  .footer-social {
    height: 10.17vw;
    width: 10.17vw;
    border-radius: 2.54vw;
    margin-top: 22.93px;
  }

  .footer-social img {
    height: 4.25vw;
  }

  .footer-top-container .social-links {
    gap: 3.81vw;
  }

  .web-footer-item {
    display: none;
  }

  .mobile-footer-item {
    display: flex;
  }

  .footer-protocol-help {
    flex-direction: row;
    display: flex;
  }

  .footer-top-container > .footer-protocol-help .mobile-footer-item:first-child {
    margin-right: 8.65vw;
  }

  .footer-top-container > .footer-protocol-help .mobile-footer-item:first-child > p a {
    margin-top: 2.54vw !important;
    font-size: 3.05vw !important;
  }

  .footer-top-container > .footer-protocol-help .mobile-footer-item:first-child > p:first-child, .footer-top-container > .footer-protocol-help .mobile-footer:first-child > p:first-child a {
    margin-top: 5.08vw !important;
    font-size: 3.05vw !important;
  }

  .mobile-footer-item {
    flex-direction: column;
    display: flex;
  }

  .mobile-footer-item b {
    color: #55e5ff;
    font-size: 4.07vw;
    font-weight: 600;
  }

  .mobile-footer-item p:first-of-type {
    color: #ffffffd9;
    margin-top: 5.08vw;
  }

  .mobile-footer-item p {
    color: #ffffffd9;
    margin: 2.54vw 0 0;
    font-size: 3.05vw;
    font-weight: 600;
  }

  .mobile-footer-item p a {
    color: #ffffffd9;
    text-decoration: none;
  }

  .footer-top-container > .footer-item:first-child {
    margin-bottom: 12.72vw;
  }

  .footer-top-container > .footer-item:last-child {
    margin-top: 7.63vw;
  }

  .footer-top-container > .footer-item:last-child > p:first-of-type {
    margin-top: 5.59vw;
  }

  .footer-top-container > .footer-item:last-child > p a {
    font-size: 3.05vw;
    line-height: 3.81vw;
  }

  .footer-top-container > .footer-item:last-child > p {
    margin-top: 2.54vw;
    margin-bottom: 0 !important;
  }

  .footer-top-container {
    padding-bottom: 5.08vw;
  }

  .footer-bottom-container {
    border: 0;
    flex-direction: row;
    padding-top: 5.08vw;
    padding-bottom: 7.63vw;
    display: flex;
  }

  .footer-bottom-container .logo-planet {
    width: 8.65vw;
    height: 8.44vw;
  }

  .footer-bottom-container .logo b {
    align-items: center;
    margin: 0 2.54vw 0 0;
    font-size: 4.32vw;
    font-weight: bold;
    display: flex;
  }

  .coins-prices-footer-container {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .coins-prices-footer img {
    height: 6.022vw;
    width: 6.338vw;
  }

  .footer-bottom-right {
    flex-direction: column;
    gap: 5.08vw;
    display: flex;
  }

  .coins-prices-footer {
    justify-content: space-between;
    gap: 2.54vw;
  }

  .coins-prices-footer b {
    font-size: 3.05vw;
  }

  .gamma-arrow-icon {
    width: 3.63vw;
    height: 2.42vw;
    padding-left: 5.91vw;
  }

  .footer-bottom-left {
    justify-content: flex-start;
    padding-left: 2.54vw;
  }

  .footer-bottom-right {
    padding-right: 2.54vw;
  }

  .footer-blue-button-ui {
    font-size: 3.3vw;
    line-height: 3.56vw;
  }

  .coins-prices-footer-container {
    min-height: 8.89vw;
  }

  .launch-app-button-nav {
    margin-top: 6.54vw;
    padding: 2vw 1.4vw;
  }

  .header-container {
    background-image: radial-gradient(#0d326d 0%, #000 80%) !important;
  }

  .header-two {
    background-image: url("green-tiled-bg.7476ab0f.webp");
  }

  .mob-nav-textCont p {
    text-transform: capitalize;
  }

  .section-five {
    overflow-x: hidden;
  }

  .mob-nav-cont a {
    text-decoration: none;
    display: flex;
  }

  .mob-nav-cont a .launch-app-button-nav {
    width: 100%;
  }

  .launch-app-button-nav:hover, .launch-app-button-nav:focus, .launch-app-button-nav:active {
    background-color: #000;
    color: #fff !important;
  }
}

@media only screen and (width >= 1024px) and (width <= 1280px) {
  .boost-earnings-container {
    padding-top: 14.5vw;
  }
}

@media only screen and (width >= 768px) and (width <= 1023px) {
  .boost-earnings-container {
    padding-top: 13.5vw;
  }
}

@media only screen and (width >= 1281px) and (width <= 1920px) {
  .boost-earnings-container {
    padding-top: 14.3vw;
  }
}

/*# sourceMappingURL=index.9eca05f4.css.map */
